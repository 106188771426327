import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    respCategoryData: null,
    respPartnerData: null,
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    GET_CATEGORY(state, payload) {
      state.respCategoryData = payload.map(e => ({
        _id: e._id,
        name: e.name,
      }))
    },
    GET_PARTNER(state, payload) {
      state.respPartnerData = payload.map(e => ({
        _id: e._id,
        name: `${e.firstName} ${e.lastName}`,
      }))
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    UPDATE(state, payload) {
      const productIndex = state.respData.data.findIndex(p => p._id === payload._id)
      Object.assign(state.respData.data[productIndex], payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    UPDATE_STOCK(state, payload) {
      console.log('UPDATE_STOCK')
      const productIndex = state.respData.data.findIndex(p => p._id === payload.product._id)
      Object.assign(state.respData.data[productIndex], payload.product)
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&categories=${item.categories.join()}&partners=${item.partners.join()}&searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getCategory({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/getCategory?searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_CATEGORY', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getPartner({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/getPartner?searchQuery=${item.searchQuery}`)
          .then(response => {
            commit('GET_PARTNER', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    view({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/view?id=${item.id}`)
          .then(response => {
            // commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/hero/product/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.delete(`/api/web/application/hero/product/delete?id=${item}`)
          .then(response => {
            commit('REMOVE', item)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/product/update', item)
          .then(response => {
            commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateInView({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/product/updateInView', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    updateCategory({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/product/updateCategory', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getStock({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/getStock?id=${item.id}`)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    saveStock({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/product/saveStock', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    import({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/web/application/hero/product/import', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    // eslint-disable-next-line no-unused-vars
    getStockProduct({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/getStockProduct?id=${item.id}`)
          .then(response => {
            commit('UPDATE_STOCK', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    saveStockProduct({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/product/saveStockProduct', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getImageProductOption({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/hero/product/getImageProductOption?id=${item.id}`)
          .then(response => {
            // commit('UPDATE_STOCK', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    saveImageProductOption({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/hero/product/saveImageProductOption', item)
          .then(response => {
            // commit('UPDATE', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
