<template>
  <b-modal
    size="lg"
    :visible="isModalActive"
    :title="$t('Stock')"
    ok-title="Accept"
    @change="(val) => $emit('update:is-modal-active', val)"
  >

    <validation-observer ref="formData">
      <b-form class="p-1">

        <b-row class="mt-1">
          <b-col cols="12">
            <b-form-group
              :label="$t('Stock')"
              label-for="register-stock"
            >
              <validation-provider
                #default="{ errors }"
                name="Stock"
                vid="stock"
                rules="required"
              >
                <cleave
                  id="register-stock"
                  v-model="stock"
                  class="form-control"
                  :raw="false"
                  :options="optionCleave.number"
                  :placeholder="$t('Stock')"
                />
                <small class="text-danger">{{ errors[0] }}</small>

              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-if="dataStockTable.length<2"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="d-flex mb-2"
              @click="addCategory()"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </div>
          <b-row
            v-for="(itemCategory, indexCategory) in dataStockTable"
            :key="indexCategory"
          >
            <b-col cols="4">
              <b-form-group
                :label="$t('Category')"
                label-for="register-category"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Category"
                  vid="category"
                  rules=""
                >
                  <div class="d-flex align-items-center justify-content-start">

                    <b-form-input
                      id="register-option"
                      v-model="itemCategory.name"
                      class="d-inline-block"
                      :placeholder="$t('Option')"
                    />
                    <b-button
                      v-if="dataStockTable.length>1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="danger"
                      class="d-flex ml-1"
                      @click="deleteCategory(indexCategory)"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>
                  </div>

                  <small class="text-danger">{{ errors[0] }}</small>

                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="8">
              <b-row
                v-for="(itemOption, indexOption) in itemCategory.option"
                :key="indexOption"
              >
                <b-col cols="12">
                  <b-form-group
                    :label="$t('Option')"
                    label-for="register-option"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Option"
                      vid="option"
                      rules=""
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                          id="register-option"
                          v-model="itemOption.name"
                          class="d-inline-block"
                          :placeholder="$t('Option')"
                        />
                        <b-button
                          v-if="itemCategory.option.length>1"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="danger"
                          class="d-flex ml-1"
                          @click="deleteOption(indexCategory, indexOption)"
                        >
                          <feather-icon
                            icon="TrashIcon"
                          />
                        </b-button>
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          size="sm"
                          variant="primary"
                          class="d-flex ml-1"
                          @click="addOption(indexCategory)"
                        >
                          <feather-icon
                            icon="PlusIcon"
                          />
                        </b-button>
                      </div>
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                  </b-form-group>

                </b-col>

              </b-row>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
    <template #modal-footer>
      <div class="w-100">

        <div
          class="float-right"
        >

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-2"
            @click="validationForm"
          >
            {{ $t('Accept') }}
            <feather-icon
              icon="ShoppingBagIcon"
              size="12"
            />
          </b-button>

        </div>

      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BModal,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    Cleave,
    BModal,
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      stock: '', // สถานะการทำ api สำหรับ web Scraping
      dataStockTable: [
        {
          _id: null,
          name: '',
          option: [{
            _id: null,
            productCategoryOptionId: null,
            name: '',
          }],
        },
      ],
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },

  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            _id, stock,
          } = this.data
          this.dataId = _id
          this.stock = stock
          if (_id) {
            this.getStockProduct(_id)
          }
        }
      }
    },
  },
  methods: {
    getStockProduct(id) {
      this.$emit('update:show', true)

      store
        .dispatch(`${this.storeModuleName}/getStockProduct`, {
          id,
        })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
        // eslint-disable-next-line no-unused-vars
          const { data, product } = result.data
          console.log(data)
          if (data.length > 0) {
            this.dataStockTable = data
          }
          if (product) {
            this.stock = product.stock
          }
          this.$emit('update:show', false)

          
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.$emit('update:show', false)

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    saveStockProduct() {
      this.$emit('update:show', true)

      store
        .dispatch(`${this.storeModuleName}/saveStockProduct`, {
          id: this.dataId,
          stockData: this.dataStockTable.filter(e => e.name !== '' && e.option.every(i => i.name !== '')),
          stock: this.stock.replaceAll(',', ''),
        })
      // eslint-disable-next-line no-unused-vars
        .then(result => {
        // eslint-disable-next-line no-unused-vars
          const { data } = result.data
          console.log(data)
          if (data.length > 0) {
            this.dataStockTable = data
          }
          this.$emit('update:show', false)

          
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.$emit('update:show', false)

          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        // console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    addCategory() {
      this.dataStockTable.push({
        _id: null,
        name: '',
        option: [{
          _id: null,
          productCategoryOptionId: null,
          name: '',
        }],
      })
    },
    deleteCategory(indexCategory) {
      this.dataStockTable.splice(indexCategory, 1)
    },
    deleteOption(indexCategory, indexOption) {
      this.dataStockTable[indexCategory].option.splice(indexOption, 1)
    },
    addOption(indexCategory) {
      this.dataStockTable[indexCategory].option.push({
        _id: null,
        name: '',
      })
    },
    initValues() {
      this.dataId = null
      this.stock = ''
      this.dataStockTable = [
        {
          _id: null,
          name: '',
          option: [{
            _id: null,
            productCategoryOptionId: null,
            name: '',
          }],
        },
      ]
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            this.saveStockProduct()
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
